// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// DEV
//export const environment = {
//    production: false,
//    name: "local",
//    host: "https://jfwd67dftg.execute-api.us-east-1.amazonaws.com/dev",
//    userPoolId: 'us-east-1_9NezbxHWQ',
//    userPoolWebClientId: '6ki2cak0u21iihhkeo4rv2kgeo',
//    gaTrackingId: 'G-6DTECKFE8F',
//    bulkMessageMemberCount: 50,
//    url: "https://microsite-dev.nsparc.org"
//};
// DEV
// export const environment = {
//     production: false,
//     name: "dev",
//     host: "https://jfwd67dftg.execute-api.us-east-1.amazonaws.com/dev",
//     userPoolId: 'us-east-1_9NezbxHWQ',
//     userPoolWebClientId: '6ki2cak0u21iihhkeo4rv2kgeo',
//     gaTrackingId: 'G-6DTECKFE8F',
//     bulkMessageMemberCount: 50,
//     url: "https://microsite-dev.nsparc.org"
// };

// QA
// export const environment = {
//     production: false,
//     name: "qa",
//     host: "https://ceq3d4uxod.execute-api.us-east-1.amazonaws.com/qa",
//     userPoolId: 'us-east-1_jmDW0Ew8s',
//     userPoolWebClientId: '1ilosl96llp9iplr4l0tplssm4',
//     gaTrackingId: 'G-Z39K6VPZGM',
//     bulkMessageMemberCount: 50,
//     url: "https://microsite-qa.nsparc.org"
// };
//UAT
 export const environment = {
         production: false,
         name: "uat",
         host: "https://1s8vpnj6pa.execute-api.us-east-1.amazonaws.com/uat",
         userPoolId: 'us-east-1_AtwTgZnxm',
         userPoolWebClientId: '6oud95hrmqqo215n2fm5mpdocb',
         gaTrackingId: 'G-9QXHK1ZG69',
         bulkMessageMemberCount: 50,
         url: "https://microsite-uat.nsparc.org"
     };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
